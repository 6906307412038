<template>
    <div class="test-repeat">
        <div class="test-repeat__head"> <!-- отображается один из блоков __head -->
            <div class="test-repeat__pic">
                <img class="test-repeat__img" src="~assets/img/favicons/two-list-icon.png" alt="" width="70"
                     height="70">
            </div>
            <div class="test-repeat__title">
                Для прохождения тестирования ознакомьтесь с новыми статьями и
                обновлениями
                <router-link class="attestation-statistics__more" to="/knowledge/?learn=1">
                    базы знаний
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Study",
}
</script>

<style scoped>

</style>
